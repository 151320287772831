import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Transaction from "../Transaction/Transaction";
import Settlement from "../Settlement/Settlement";
import Refunds from "../Transaction/Refunds";

function Payments({ menu }: any) {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="flex">
      <div
        className={
          "  transition-transform duration-200 overflow-hidden sticky top-0 min-h-screen shrink-0 pt-10 " +
          (menu ? "translate-x-0 w-56" : " -translate-x-[100%] w-0 ")
        }
      >
        <div className=" flex flex-col ">
          <button
            onClick={() => setActiveTab(1)}
            className={
              activeTab === 1
                ? "bg-[#6687FF33] bg-opacity-20  py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : "  py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Transactions
          </button>
          <button
            onClick={() => setActiveTab(2)}
            className={
              activeTab === 2
                ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Settlements
          </button>
          {/* <button
            onClick={() => setActiveTab(3)}
            className={
              activeTab === 3
                ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
            }
          >
            Refunds
          </button> */}
        </div>
      </div>
      <div className="tab-content w-full lg:pr-8 pr-4">
        {activeTab === 1 && <Transaction />}
        {activeTab === 2 && <Settlement />}
        {/* {activeTab === 3 && <Refunds />} */}
      </div>
    </div>
  );
}

export default Payments;
